import { Component, input, OnInit } from '@angular/core'
import { MatPaginator, PageEvent } from "@angular/material/paginator"
import { MatCell, MatCellDef, MatColumnDef, MatHeaderCell, MatHeaderCellDef, MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef, MatTable, MatTableDataSource } from "@angular/material/table"
import { EMPTY, Observable, tap } from 'rxjs'
import { switchMap } from 'rxjs/operators'

import { MatMiniFabButton } from '@angular/material/button'
import { MatIcon } from '@angular/material/icon'
import { MatProgressBar } from '@angular/material/progress-bar'
import { TranslatePipe } from '@ngx-translate/core'
import { Page } from '../../shared.api'
import { Invitation } from '../invite-api'
import { InviteService } from '../invite.service'

@Component({
  selector: 'lib-invitation-list',
  templateUrl: './invitation-list.component.html',
  styleUrls: ['./invitation-list.component.scss'],
  imports: [MatProgressBar, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatMiniFabButton, MatIcon, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatPaginator, TranslatePipe]
})
export class InvitationListComponent implements OnInit {
  companyId = input<string | undefined>()
  displayedColumns = ['name', 'email', 'timestamp', 'status', 'actions']
  dataSource: MatTableDataSource<Invitation> = new MatTableDataSource()

  reloading = false
  pageSize = 10
  pageIndex = 0
  totalSize = -1

  constructor(private readonly service: InviteService) {
  }

  ngOnInit() {
    this.loadData(0, this.pageSize).subscribe()
  }

  handlePaginatorEvent(event: PageEvent) {
    this.loadData(event.pageIndex, event.pageSize).subscribe()
  }

  private loadData(page: number, size: number): Observable<Page<Invitation>> {
    if (this.reloading) {
      return EMPTY
    }
    this.reloading = true

    return this.service.getByCompany(this.companyId(), page, size).pipe(
      tap({
        next: (data) => {
          this.dataSource.data = data.content
          this.pageIndex = data.pageable.number
          this.pageSize = data.pageable.size
          this.totalSize = data.totalSize
        },
        error: (error) => {
          console.error('Error loading invitations:', error)
        },
        finalize: () => {
          this.reloading = false
        }
      })
    )
  }

  getTranslation(status: string) {
    switch (status) {
      case 'ACCEPTED':
        return 'invitationStatus_accepted'
      case 'SEND':
        return 'invitationStatus_pending'
      case 'EXPIRED':
        return 'invitationStatus_declined'
      case 'INITIAL':
        return 'initial'
    }
    return status
  }

  cancelInvitation(id: number) {
    if (!id) return
    this.service.cancel(id).pipe(
      switchMap(() => this.loadData(0, this.pageSize))
    ).subscribe()
  }

  resend(id: number) {
    if (!id) return
    this.service.resend(id).pipe(
      switchMap(() => this.loadData(0, this.pageSize))
    ).subscribe()
  }
}
