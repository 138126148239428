import { HttpResponse } from "@angular/common/http"
import { Injectable } from '@angular/core'
import { Observable } from "rxjs"
import { BaseService } from "../base.service"
import { Page } from "../shared.api"
import { Invitation, InviteRequest } from "./invite-api"

@Injectable({
  providedIn: 'root'
})
export class InviteService extends BaseService {

  constructor() {
    super('invite')
    this.retryCount = 1
  }


  cancel(id: number) {
    const path = '' + id
    return this.delete(path)
  }

  getByCompany(id: string | undefined, page: number, size: number): Observable<Page<Invitation>> {
    const url = id ? `company/${id}` : ''
    return this.getPaged(url, page, size)
  }

  invite(request: InviteRequest): Observable<Invitation> {
    return this.post('', request)
  }

  inviteToCompany(id: string | undefined, request: InviteRequest): Observable<Invitation> {
    return id ? this.post(`company/${id}`, request) : this.invite(request)
  }

  accept(key: string): Observable<HttpResponse<Invitation>> {
    const queryParams = '?key=' + key
    return this.post('accept' + queryParams, {})
  }

  resend(id: number): Observable<Invitation> {
    return this.post(id + '/resend', {})
  }
}
