@if (reloading) {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
}
<table mat-table [dataSource]="dataSource">

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef> {{'name' | translate}}</th>
    <td mat-cell *matCellDef="let invitation">{{invitation.firstName}} {{invitation.lastName}}</td>
  </ng-container>

  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef> {{'contactMail' | translate}}</th>
    <td mat-cell *matCellDef="let invitation">{{invitation.email}}</td>
  </ng-container>

  <ng-container matColumnDef="timestamp">
    <th mat-header-cell *matHeaderCellDef> {{'timestamp' | translate}}</th>
    <td mat-cell *matCellDef="let invitation">{{invitation.timestamp}}</td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef> {{'invitationStatus' | translate}}</th>
    <td mat-cell *matCellDef="let invitation">{{getTranslation(invitation.status) | translate}}</td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef> {{'actions' | translate}}</th>
    <td mat-cell *matCellDef="let invitation">
      <div class="flex flex-row gap-2 justify-center">
        <button mat-mini-fab color="primary" (click)="resend(invitation.id)">
          <mat-icon>mail</mat-icon>
        </button>
        <button mat-mini-fab color="primary" (click)="cancelInvitation(invitation.id)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header"></tr>
  <tr mat-row *matRowDef="let invitation; columns: displayedColumns;" class="hover-effect"></tr>

</table>

<mat-paginator
  [length]="totalSize"
  [pageSize]="pageSize"
  [pageIndex]="pageIndex"
  [pageSizeOptions]="[5, 10, 25, 100]"
  showFirstLastButtons
  (page)="handlePaginatorEvent($event)">
</mat-paginator>
